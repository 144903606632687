@import "src/assets/scss/_variables.scss";

.card-bg {
  background-color: #ffffff;
}

.chat-bot-box-shadow {
  box-shadow: 0 3px 20px #969696;
  border-radius: 10px;
}

body {
  --primary-color: $primary-color;
  --secondary-color: $secondary-color;
  --surface-50: white;
  --bs-body-font-size: 15px;
}
